import request from '@/common/utils/request.js'
export const getCourseList = (params) => request({
  url: '/ques/examinationResult/selectResultKcList',
  method: 'GET',
  params
})
export const getDateList = (params) => request({
  url: '/ques/examinationResult/selectResultDay',
  method: 'GET',
  params
})
export const getStudentPaperResultVoList = (params) => request({
  url: '/ques/examinationResult/selectStudentPaperResultVoList',
  method: 'GET',
  params
})
export const getWrongQuestionCourseList = (params) => request({
  url: '/ques/paperSubjectMistake/selectStuMistakeKcList',
  method: 'GET',
  params
})
export const getWrongQuestionList = (params) => request({
  url: '/ques/paperSubjectMistake/selectStuMistakeSubjectIdList',
  method: 'GET',
  params
})
export const getSubjectIdList = (params) => request({
  url: '/ques/paperSubjectMistake/selectPaperMistakeSubjectDataList',
  method: 'POST',
  data: params
})

export const getPcNewSubjectAnswer = (params) =>request({
  url:"/ques/business/getPcNewSubjectAnswer",
  method:"get",
  data:params
})