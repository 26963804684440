import { render, staticRenderFns } from "./examDetailpartner.vue?vue&type=template&id=abc6b9e8&scoped=true&"
import script from "./examDetailpartner.vue?vue&type=script&lang=js&"
export * from "./examDetailpartner.vue?vue&type=script&lang=js&"
import style0 from "./examDetailpartner.vue?vue&type=style&index=0&id=abc6b9e8&prod&lang=less&scoped=true&"
import style1 from "./examDetailpartner.vue?vue&type=style&index=1&id=abc6b9e8&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abc6b9e8",
  null
  
)

export default component.exports