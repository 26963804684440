<template>
  <div>
    <div v-if="subjectTypeList.length > 0" class="sheet-subject">{{ getSubjectType() }}<span>（共{{ subjectTypeList.length
    }}题，合计{{ getFraction(subjectTypeList) }}分）</span></div>
    <div v-if="subjectTypeList.length > 0" style="display: flex;flex-wrap: wrap;">
      <div v-for="(shi, shiIndex) of subjectTypeList" :key="shiIndex" class="t-item">
        <div v-if="isSubmit" :class="!shi.questionUserAnswer ? 't-item' : 't-item-hasanswer'"
          @click="jumpShiTi(shi, shiIndex)">
          {{ shiIndex + 1 }}
          <!-- <i v-if="collectList.includes(shi.id)" class="el-icon-star-on collec-icon" /> -->
        </div>
        <!-- 未提交 -->
        <div v-else
          :class="!shi.questionUserAnswer || shi.questionUserAnswer.length === 0 ? 't-item' : 't-item-hasanswer'"
          @click="jumpShiTi(shi, shiIndex)">
          {{ shiIndex + 1 }}
          <!-- <i v-if="collectList.includes(shi.id)" class="el-icon-star-on collec-icon" /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
export default {
  name: 'AnswerSheet',
  props: {
    subjectTypeList: {
      type: Array,
      default: () => []
    },
    isSubmit: {
      type: Boolean,
      default: false
    },
    collectList: {
      type: Array,
      default: () => []
    },
    sindex: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {

    }
  },
  methods: {
    jumpShiTi(item, index) {
      // const num = {
      //   0: '一',
      //   1: '二',
      //   2: '三',
      //   3: '四',
      //   4: '五',
      //   6: '六',
      //   7: '七',
      //   8: '八'
      // }
      // const data = {
      //   id: item.id,
      //   subjectIndex: item.subjectIndex,
      //   typeIndex: num[],
      //   typeTotal: this.subjectTypeList.length,
      //   subjectTotal: this.getFraction(this.subjectTypeList)
      // }/
      this.$emit('jumpShiTi', item.id, this.sindex, this.subjectTypeList.find((s) => s.id == item.id).subjectIndex)
    },
    getFraction(list) {
      let fraction = 0
      list.map((item) => {
        fraction += item.score
      })
      return fraction
    },
    getSubjectType() {
      const mapping = {
        0: '单选题',
        1: '多选题',
        2: '判断题',
        3: '填空题',
        4: '主观题',
        5: '组合题'
      }
      return mapping[this.subjectTypeList[0].questionType]
    }
  }
}
</script>
<style lang="less" scoped>
.sheet-block {
  // max-height: 360px;
  overflow-y: auto;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeeee;

  .t-item {
    width: 22px;
    height: 22px;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
    margin: 8px 12px 10px 0px;

    .t-item {
      border: 1px solid #eeeeee;
      line-height: 22px;
      margin: 0px;
    }

    .t-item-hasanswer {
      border: 1px solid #eeeeee;
      background-color: #eeeeee;
      line-height: 22px;
      height: 22px;
      border-radius: 3px 3px 3px 3px;
      margin: 0px;
    }

    &:hover {
      background-color: #eeeeee;
    }
  }

  .sheet-subject {
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    margin: 10px 0px;
    color: #666666;

    span {
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    }
  }
}

.collec-icon {
  color: rgb(255, 149, 42);
  position: relative;
  right: -6px;
  top: -30px;
}
</style>
